<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <!-- 头部 -->
            <div class="head">
                <div style="position: absolute;top: 50%;left: 30px;color: #65ffff;font-size: 32px;display: flex;flex-direction: row;align-items: center;"
                    @click="back">
                    <span>◁</span><span style="margin-top: -4px;">━</span>
                    <span v-if="lang == 'zh'" style="margin-left: 8px;font-size: 22px;">返回</span>
                    <span v-else style="margin-left: 8px;font-size: 22px;">go back</span>
                </div>
                <div style="font-size: 45px;text-align: center;color: #fff;">电能统计界面</div>
                <div style="font-size: 27px;text-align: center;color: #fff;">Electricity Statistics</div>
            </div>

            <div class="info">
                <div class="listData">
                    <div class="item" v-for="(item, index) in listData" :key="index">
                        <div class="itemName">
                            <p>{{ item.name }}</p>
                            <p style="margin-top: 5px;">{{ item.deviceName }}</p>
                        </div>
                        <div class="itemData">
                            <div class="itembattery">
                                <p>{{ BatteryFun(index) }}<span style="font-size: 16px;">%</span></p>
                                <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '剩余电量' : 'Battery' }}</p>
                            </div>
                            <div class="itembattery">
                                <p>{{ item.differFrom }}</p>
                                <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '压差' : 'differential pressure' }}</p>
                            </div>
                            <div class="itembattery">
                                <p>{{ item.big }}</p>
                                <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '单体最高电压' : 'ceiling voltage' }}</p>
                            </div>
                            <div class="itembattery">
                                <p>{{ item.small }}</p>
                                <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '单体最低电压' : 'minimum voltage' }}</p>
                            </div>
                            <div class="itembattery">
                                <p>{{ item.chargePower ? item.chargePower : 0 }}</p>
                                <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '充电电量' : 'Charging capacity' }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="switchBtn">
                    <div class="next" @click="next('up')">
                        {{ lang == 'zh' ? '上一页' : 'up' }}
                    </div>

                    <ul class="pagination">
                        <!-- 起始页码 -->
                        <li v-if="showStartDots" class="page-item_dots">...</li>

                        <!-- 显示的页码 -->
                        <li v-for="page in visiblePages()" :key="page" class="pageNumber"
                            :class="{ 'page-link': page === currentPage }" @click="goToPage(page)">
                            {{ page }}
                        </li>

                        <!-- 中间省略号 -->
                        <li v-if="showMiddleDots" class="page-item_dots">...</li>

                        <!-- 末尾页码 -->
                        <li v-if="showEndDots" class="page-item_dots">...</li>

                    </ul>

                    <!-- <div class="pageNumber">1</div>
                            <div class="pageNumber">2</div>
                            <div class="pageNumber">3</div> -->

                    <div class="next" @click="next('down')">
                        {{ lang == 'zh' ? '下一页' : 'down' }}
                    </div>
                </div>


            </div>


        </div>
    </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";
import { deviceListAPI } from "@/api";

export default {
    mixins: [drawMixin],
    name: '',
    components: {},
    props: {},
    data() {
        return {
            lang: 'zh',
            project_id: '',
            grouping_id: '',
            currentPage: 1,
            listData: [],

            totalPages: 9, // 假设总页数是 100
            pageSize: 4, // 每页显示 5 个页码
            showStartDots: false,
            showMiddleDots: true,
            showEndDots: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        let query = this.$route.query; // { key1: value1, key2: value2 }
        if(query.g) {
            this.grouping_id = query.g
        }else {
            this.project_id = query.p
        }

        if (localStorage.getItem('lang')) {
            this.lang = localStorage.getItem('lang')
        }

        this.deviceList()
    },
    methods: {
        // 返回
        back() {
            this.$router.back();
        },

        async deviceList() {
            let obj = { project_id: this.project_id, page: this.currentPage }
            if(this.grouping_id) obj = { grouping_id: this.grouping_id, page: this.currentPage }
            const res = await deviceListAPI(obj);
            this.listData = res.data
            if (this.listData.length == 0) {
                alert('暂无数据')
                this.back()
                return
            }

            for (let i = 0; i < this.listData.length; i++) {
                this.differential(i)
            }

            this.totalPages = res.last_page
        },

        goToPage(page) {
            if (page < 1 || page > this.totalPages || page === this.currentPage) return;
            this.currentPage = page;

            this.deviceList()
        },

        // 剩余电量
        BatteryFun(index) {
            let arr = []
            let ii = 8
            if ((this.listData[index][`bat_9_vol`] / 1000).toFixed(3) > 2) {
                ii = 9
            }
            for (var i = 1; i <= ii; i++) {
                if (this.listData[index][`bat_${i}_soc`]) {
                    arr.push(this.listData[index][`bat_${i}_soc`] * 1)
                }
            }
            arr.sort((a, b) => a - b); // 升序
            // 移除最大值和最小值
            arr.shift(); // 移除最小值
            arr.pop();   // 移除最大值
            // 计算剩余元素的平均值
            // console.log('剩余电量', arr)
            const sum = arr.reduce((total, num) => total + num, 0);
            // console.log('剩余电量', sum)
            return (sum / arr.length).toFixed(0)
        },
        // 压差
        differential(index) {
            let arr = []
            let ii = 8
            // console.log(index);

            if ((this.listData[index][`bat_9_vol`] / 1000).toFixed(3) > 2) {
                ii = 9
            }
            for (var i = 1; i <= ii; i++) {
                if (this.listData[index][`bat_${i}_vol`]) {
                    this.listData[index][`bat_${i}_vol`] = (this.listData[index][`bat_${i}_vol`] / 1000).toFixed(3)
                    arr.push(this.listData[index][`bat_${i}_vol`])
                }
            }
            arr.sort((a, b) => a - b); // 升序

            this.listData[index]['big'] = arr[0]
            this.listData[index]['small'] = arr[arr.length - 1]
            this.listData[index]['differFrom'] = (arr[arr.length - 1] - arr[0]).toFixed(3)


        },
     

        // 换也
        next(type) {
            if (type == 'up') {
                if (this.currentPage > 1) {
                    this.currentPage--
                } else {
                    return
                }
            } else {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++
                } else {
                    return
                }
            }

            this.deviceList()
        },



        visiblePages() {
            const start = Math.max(1, this.currentPage - Math.floor(this.pageSize / 2));
            const end = Math.min(this.totalPages, start + this.pageSize - 1);

            this.showStartDots = start > 1;
            this.showMiddleDots = end < this.totalPages && (start > 2 || end < this.totalPages - 1);
            this.showEndDots = end < this.totalPages - 1;

            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        },

    }
}
</script>
<style lang='scss' scoped>
#index {
    color: #d3d6dd;
    width: 1920px;
    height: 1080px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    overflow: hidden;

    .bg {
        width: 100%;
        height: 100%;
        padding: 16px 16px 0 16px;
        background-image: url("../../assets/pageBg.png");
        background-size: cover;
        background-position: center center;
    }
}

.head {
    position: relative;
    width: 100%;
    height: 11vh;
    background: url("../../assets/BMS/Group 70@2x.png") no-repeat;
    background-size: cover;
    background-position: center center;
}

.info {
    margin-top: 20px;
    box-shadow: inset 0px 1px 30px 20px rgba(10, 125, 140, 0.3);

    .listData {
        padding: 20px;
        height: 92vh;
        overflow-y: scroll;

        .item {
            display: flex;
            border: 1px solid rgba(10, 125, 140);
            padding: 15px 10px;

            .itemName {
                width: 417px;
                height: 92px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 5%;
                background: url("../../assets/BMS/Group 73@2x.png") no-repeat;
                background-size: cover;
                background-position: center center;
            }

            .itemData {
                flex: 1;
                display: flex;
                justify-content: space-evenly;

                .itembattery {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 190px;
                    height: 92px;
                    background: url("../../assets/BMS/Group@2x(1).png") no-repeat;
                    background-size: contain;
                    background-position: center 0px;
                    color: #66FFFF;
                    font-size: 28px;
                }
            }
        }
    }

    .listData::-webkit-scrollbar {
        width: 8px;
    }

    .listData::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(12, 128, 144, .5);
    }

    .listData::-webkit-scrollbar-track {
        border-radius: 0;
        background: rgba(255, 255, 255, 0.2);
    }


    .switchBtn {
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 20px;

        .pagination {
            // flex: 1;
            width: 30%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .pageNumber {
                background: url("../../assets/BMS/Rectangle 36@2x.png") no-repeat;
                background-size: contain;
                background-position: center 0px;
                padding: 5px 10px;
            }

            .page-item_dots {
                background: url("../../assets/BMS/Rectangle 36@2x.png") no-repeat;
                background-size: contain;
                background-position: center 0px;
                padding: 5px 10px;
            }

            .page-link {
                color: #66ffff;
            }
        }

        .next {
            background: url("../../assets/BMS/Rectangle 38@2x.png") no-repeat;
            background-size: contain;
            background-position: center 0px;
            padding: 4px 10px;
        }
    }

}
</style>